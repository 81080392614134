import Main from "../components/Main";

const Home = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default Home;
